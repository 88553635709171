import classNames from 'classnames';
import { graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import blockConductor from '../blocks/block-conductor';
import Header from '../components/header';
import Image from '../components/image';
import Layout from '../components/layout';
import Link from '../components/link';
import Markdown from '../components/markdown';
import SEO from '../components/SEO';
import sectionConductor from '../sections/section-conductor';
import SectionOpener from '../sections/section-opener/section-opener';
import { resolve } from '../urls';

class SingleOfficePage extends Component {
  render() {
    const { data, location } = this.props;
    const { contentfulOffices: page, allContentfulSectionOfficeList } = data;
    const { sections3: sections } = page;
    const { city } = page;

    const officeStructuredData = [
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        'name': `Edenspiekermann ${page.city}`,
        'image': page.headerImage ? page.headerImage.file.url : '',
        'telephone': page.phone_number ? page.phone_number[0] : '',
        'email': page.contact_email ? page.contact_email : '',
        'url': location.href,
        'address': {
          '@type': 'PostalAddress',
          'streetAddress': page.street_address ? page.street_address : '',
          'addressLocality': page.locality ? page.locality : '',
          'addressRegion': page.region ? page.region : '',
          'addressCountry': page.country ? page.country.name : '',
          'postalCode': page.postal_code ? page.postal_code : '',
        },
      },
    ];

    const pageWrapClasses = classNames({
      'page': true,
      'page--office': true,
    });

    return (
      <div className={pageWrapClasses}>
        <Layout navbarIsInverted={false}>
          {page.city && (
            <SEO
              title={page.city}
              description={`${page.city} office of Edenspiekermann`}
              shareImage={getSrc(page.main_image)}
              structuredData={officeStructuredData}
              metadata={page.metadata}
            />
          )}
          <SectionOpener openerType="office">
            <div className="section-opener-office__content-wrap">
              {page.city && <Header heading={page.city} label="Our Offices" />}
              <div className="copy__wrap">
                {page.fullAddress && (
                  <Markdown markdown={page.fullAddress.childMarkdownRemark.html} />
                )}
                {page.phone_number && (
                  <p className="section-opener-office__phone">{page.phone_number[0]}</p>
                )}
              </div>
              {page.contact_email && (
                <a
                  className="section-opener-office__contact button-icon"
                  href={`mailto:${page.contact_email}`}
                >
                  Contact Us
                </a>
              )}
              <div className="section-opener-office__intro-wrap">
                {page.headline && <h3>{page.headline}</h3>}
                {page.introduction && (
                  <Markdown markdown={page.introduction.childMarkdownRemark.html} />
                )}
              </div>
            </div>

            {page.headerImage && (
              <div className="section-opener-office__img-wrap">
                <Image
                  className="section-opener-office__img"
                  image={page.headerImage}
                  alt={page.headerImage.title}
                />
              </div>
            )}
          </SectionOpener>
          <div className="section-opener-office__content-wrap-second">
            {page.headline && <h3>{page.headline}</h3>}

            {page.introduction && (
              <Markdown markdown={page.introduction.childMarkdownRemark.html} />
            )}
          </div>
          <div>
            {page.body_blocks && page.body_blocks.map((block, i) => blockConductor(block, i))}
          </div>

          {/* Render sections */}
          {sections && sections.map((section, i) => sectionConductor({ ...section, city }, i))}

          {page.leadership && (
            <div className="office-leadership">
              <div className="office-leadership__pad-wrap">
                <div className="office-leadership__header">
                  <div className="label">Leadership</div>
                </div>
                <div className="office-leadership__people">
                  {page.leadership &&
                    page.leadership.map((person, i) => (
                      <div className="office-leadership__person" key={i}>
                        <Link
                          to={resolve('people:detail', {
                            peopleSlug: person.slug,
                          })}
                        >
                          <Image image={person.avatar} alt={person.avatar.title} />
                        </Link>
                        {person.name && (
                          <div className="office-leadership__name">{person.name}</div>
                        )}
                        {person.position && (
                          <div className="office-leadership__title">{person.position}</div>
                        )}
                        {page.city && (
                          <div className="office-leadership__location">{`Edenspiekermann ${page.city}`}</div>
                        )}
                        {person.description && (
                          <div className="office-leadership__bio">
                            <Markdown markdown={person.description.childMarkdownRemark.html} />
                          </div>
                        )}
                        {person.email && (
                          <a className="office-leadership__contact" href={`mailto:${person.email}`}>
                            {`Contact ${person.shortName ? person.shortName : person.name}`}
                          </a>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}

          <>{sectionConductor(allContentfulSectionOfficeList.edges[0].node, 0)}</>
        </Layout>
      </div>
    );
  }
}

export default SingleOfficePage;

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulOffices(slug: { eq: $slug }) {
      city
      slug
      fullAddress {
        childMarkdownRemark {
          html
        }
      }
      street_address
      locality
      region
      postal_code
      country {
        name
      }
      phone_number
      contact_email
      time_zone
      main_image {
        title
        file {
          contentType
        }
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
      headerImage {
        title
        file {
          contentType
        }
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
      headline
      introduction {
        childMarkdownRemark {
          html
        }
      }
      leadership {
        name
        slug
        shortName
        position
        email
        description {
          childMarkdownRemark {
            html
          }
        }
        avatar {
          title
          file {
            contentType
          }
          gatsbyImageData(layout: CONSTRAINED, width: 400, aspectRatio: 1.333)
        }
      }
      metadata {
        ...Metadata
      }

      sections3 {
        ...ContentfulSectionCollageFragment
        ...ContentfulSectionServiceCarouselFragment
        ... on ContentfulSectionJobList {
          __typename
          id
          heading
          filterByCity: filterByLocation
        }
      }
      ...SingleOfficeBlocksFragment
    }
    allContentfulSectionOfficeList {
      edges {
        node {
          __typename
          heading
          label
          text {
            text
          }
          offices {
            slug
            city
            main_image {
              title
              file {
                contentType
              }
              gatsbyImageData(layout: CONSTRAINED, width: 500)
            }
            time_zone
          }
        }
      }
    }
  }
`;

SingleOfficePage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
